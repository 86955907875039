import React from "react";
import {Link} from "react-router-dom";
import * as Rb from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Port_img from "./assets/img/port.png";
import Cl1 from "./assets/img/portfolio/Cl-1.png";
import Cl2 from "./assets/img/portfolio/Cl-2.png";
import Cl3 from "./assets/img/portfolio/Cl-3.png";
import Cl4 from "./assets/img/portfolio/Cl-4.png";
import Cl5 from "./assets/img/portfolio/Cl-5.png";
import Cl6 from "./assets/img/portfolio/Cl-6.png";
import Cl7 from "./assets/img/portfolio/Cl-7.png";
import Cl8 from "./assets/img/portfolio/Cl-8.png";
import Cl9 from "./assets/img/portfolio/Cl-9.png";
import Cl10 from "./assets/img/portfolio/Cl-10.png";
import Cl11 from "./assets/img/portfolio/Cl-11.png";
import Cl12 from "./assets/img/portfolio/Cl-12.png";
import Cl13 from "./assets/img/portfolio/Cl-13.png";
import Cl14 from "./assets/img/portfolio/Cl-14.png";
import Cl15 from "./assets/img/portfolio/Cl-15.png";
import Cl16 from "./assets/img/portfolio/Cl-16.png";
import Cl17 from "./assets/img/portfolio/Cl-17.png";
import Cl18 from "./assets/img/portfolio/Cl-18.png";
import Cl19 from "./assets/img/portfolio/Cl-19.png";
import Cl20 from "./assets/img/portfolio/Cl-20.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Tab from "./assets/img/tab2.png";
import Portimg_1 from "./assets/img/port_img1.png";
import Portimg_2 from "./assets/img/port_img2.png";
import Portimg_3 from "./assets/img/port_img3.png";
import Portimg_4 from "./assets/img/port_img4.png";
import Portimg_5 from "./assets/img/port_img5.png";
import Portimg_6 from "./assets/img/port_img6.png";
import Portimg_7 from "./assets/img/port_img7.png";
import Portimg_8 from "./assets/img/port_img8.png";
import Portimg_9 from "./assets/img/port_img9.png";
import Portimg_10 from "./assets/img/port_img10.png";
import Portimg_11 from "./assets/img/port_img11.png";
import Portimg_12 from "./assets/img/port_img12.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";
const test = {
    loop:true,
    margin: 0,
    merge:true,
    center:true,
    responsiveClass:true,
    callbacks:true,
    autoplay: true,
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
    },
  };
class portfolio extends React.Component
{
    render(){
       
        return(
            <div>
                  <section  className="bg-img">
                    <Rb.Container className="about_banner">
                            <Rb.Row className="banner_height_set align-items-center ">
                                <Rb.Col  sm={12} md={6} lg={6} className="col_align">
                                    <div className="banner_para_align">
                                        {/* <p className="about_head_para">PORTFOLIO</p> */}
                                        <h2 className="about_h2">PORTFOLIO</h2>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={6} lg={6}>
                                    <Rb.Image src={Port_img} alt="services_img" className="about_img_align"/>
                                </Rb.Col>
                            </Rb.Row>
                        </Rb.Container>
                </section>
                <section className="portfolio section section-padding-equal project-column-4">
                <Rb.Container>
                    <div className="section-heading heading-left mb--40 ">
                        <div className="subtitle">Our Portfolio</div>
                        <h2 className="title">Some of our <br/> finest work</h2>
                    </div>
                            <Rb.Row>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a target="blank" href="http://cobota.in/">
                                            <Rb.Image fluid src={Cl1} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">COBOTA</div>
                                        <span className="subtitle">
                                            <span>E-Commerce</span>
                                        </span>
                                    </div>
                                </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://foresightchennai.com/">
                                                <img src={Cl2} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">FORESIGHT</div>
                                            <span className="subtitle">
                                                <span>Industrial</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="http://newmoorthycafe.com/">
                                                <img src={Cl3} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">NEW MOORTHY CAFE</div>
                                            <span className="subtitle">
                                                <span>Hotel</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="http://studysmart.sg/">
                                                <img src={Cl6} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Imperial - StudySmart</div>
                                            <span className="subtitle">
                                                <span>Education</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl4} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">ANDERSON DIAGNOSTICS & LABS</div>
                                            <span className="subtitle">
                                                <span>Hospital</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl5} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">VIKATAN</div>
                                            <span className="subtitle">
                                                <span>Media</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="http://muthumeena.com/">
                                                <img src={Cl7} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Muthumeena</div>
                                            <span className="subtitle">
                                                <span>Industrial</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="http://sristibuilders.com/">
                                                <img src={Cl8} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Sristi Builders</div>
                                            <span className="subtitle">
                                                <span>Builders</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://creed.co.in/">
                                                <img src={Cl9} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">CREED</div>
                                            <span className="subtitle">
                                                <span>NGO</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl19} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Fancy Carpet Trading</div>
                                            <span className="subtitle">
                                                <span>Trading</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://www.psmpl.net/">
                                                <img src={Cl10} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">PSMPL</div>
                                            <span className="subtitle">
                                                <span>Industry</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://www.agaram.in/">
                                                <img src={Cl11} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Agaram Foundation</div>
                                            <span className="subtitle">
                                                <span>NGO</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl20} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Palaniappa Electronics</div>
                                            <span className="subtitle">
                                                <span>Retail</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl12} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Kothari Bankers</div>
                                            <span className="subtitle">
                                                <span>Banking</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://asnaint.com/">
                                                <img src={Cl13} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Asna International</div>
                                            <span className="subtitle">
                                                <span>Exporter</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://asnagas.com/">
                                                <img src={Cl13} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Asna gas</div>
                                            <span className="subtitle">
                                                <span>Industry</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a target="blank" href="https://www.rathimeena.co.in/">
                                                <img src={Cl14} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">RathiMeena Speed Parcel</div>
                                            <span className="subtitle">
                                                <span>Transport</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl17} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Shree Raghavendra Arts and Science College</div>
                                            <span className="subtitle">
                                                <span>Education</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl15} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Rakshan Fashions</div>
                                            <span className="subtitle">
                                                <span>Men's Wear</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl18} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">BV School</div>
                                            <span className="subtitle">
                                                <span>Education</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={12} lg={6} xl={4}>
                                    <div className="project-grid mx-auto">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img src={Cl16} className="" alt="icon" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="title">Velliyambalam Jewellery</div>
                                            <span className="subtitle">
                                                <span>Jewellery</span>
                                            </span>
                                        </div>
                                    </div>
                                </Rb.Col>
                            </Rb.Row>
                    <div className="section-heading heading-left mb--40 ">
                        <div className="subtitle text-center">And more...</div>
                    </div>
                </Rb.Container>
                <ul className="list-unstyled shape-group-7">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Cir1} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Cir2} alt="Circle" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Cir3} alt="Circle" />
                        </li>
                    </ul>
                </section>
               
               
                <Gototop/>
            </div>
        )
    }
}
export default portfolio