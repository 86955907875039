import React from "react";
import {Link} from "react-router-dom";
import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import * as Bi from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Device from "./assets/img/devices.png";
import Food from "./assets/img/pro1.png";
import Pawn from "./assets/img/pro2.png";
import Support from "./assets/img/pro3.png";
import Hospital from "./assets/img/pro4.png";
import Hr from "./assets/img/pro5.png";
import Retail from "./assets/img/pro6.png";
import Probubble1 from "./assets/img/pro-bubble1.png";
import Probubble2 from "./assets/img/pro-bubble2.png";
import Probubble3 from "./assets/img/pro-bubble3.png";
import Probubble4 from "./assets/img/pro-bubble4.png";
import Probubble5 from "./assets/img/pro-bubble5.png";
import Probubble6 from "./assets/img/pro-bubble6.png";
import Product_img from "./assets/img/product.png";
import Port_gold from "./assets/img/port_gold.png";
import Cart from "./assets/img/cart.png";
import Account from "./assets/img/account.png";
import Hotel from "./assets/img/hotel.png";
import ReactFullpage from '@fullpage/react-fullpage';
import Gototop from "./gototop";

function Product()
{
    
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const [open5, setOpen5] = useState(false);
	const [open6, setOpen6] = useState(false);
        return(
            <div>
                <section  className="bg-img">
                    <Rb.Container className="about_banner">
                            <Rb.Row className="banner_height_set align-items-center ">
                                <Rb.Col sm={12} md={6} lg={6} className="col_align">
                                    <div className="banner_para_align">
                                        <p className="about_head_para"></p>
                                        <h2 className="about_h2">PRODUCTS</h2>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={6} lg={6}>
                                    <Rb.Image src={Product_img} alt="services_img" className="about_img_align"/>
                                </Rb.Col>
                            </Rb.Row>
                        </Rb.Container>
                </section>

                
                <section className="section section-padding bg-color-light pb--70">
                    <div className="container">
                        <div className="row process-work mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="thumbnail">
                                    <img className="img-fluid img-pad" src={Pawn} alt="Bubble" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="content">
                                    <h3 className="titles">Pawn Broking Application</h3>
                                    <p className=" mb-3">Pawn Broking application is software for Pawn Broking, Pawn Shop, Pawn Broker, Jewel Loan Finance and Gold Finance Companies.</p>
                                    <Link to="/pawnbroking">
                                        <Rb.Button variant="btn alg_btn">Read More</Rb.Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row process-work content-reverse mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="thumbnail">
                                    <img className="img-fluid img-pad" src={Hospital} alt="Bubble" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="content cont-reverse ">
                                    <h3 className="titles">Hospital Management System</h3>
                                    <p className="cont-reverse mb-3">Hospital Management System is an integrated end-to-end web-based software application intended to make the administrative </p>
                                    <Link to="/hospitalmanagement">
                                        <Rb.Button variant="btn alg_btn">Read More</Rb.Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row process-work mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="thumbnail">
                                    <img className="img-fluid img-pad" src={Support} alt="Bubble" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="content">
                                    <h3 className="titles">Support Ticketing Tools</h3>
                                    <p className=" mb-3">Ticketing Management aids automate help desk tasks and saves ample of time for IT technicians and support Employees.</p>
                                    <Link to="/supportticket">
                                        <Rb.Button variant="btn alg_btn">Read More</Rb.Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row process-work content-reverse mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="thumbnail">
                                    <img className="img-fluid img-pad" src={Retail} alt="Bubble" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="content cont-reverse ">
                                    <h3 className="titles">Retail Application</h3>
                                    <p className="cont-reverse mb-3">To conquest the digital combat, retailers must essentially rethink how applications should be built and deployed.</p>
                                    <Link to="/retailapplication">
                                        <Rb.Button variant="btn alg_btn">Read More</Rb.Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row process-work mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="thumbnail">
                                    <img className="img-fluid img-pad" src={Hr} alt="Bubble" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="content">
                                    <h3 className="titles">Human Resource Management System</h3>
                                    <p className=" mb-3">Our TAGCS HRMS is designed and suitable for every business. This powerful and easy to use HRMS software allows</p>
                                    <Link to="/humanresourse">
                                        <Rb.Button variant="btn alg_btn">Read More</Rb.Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row process-work content-reverse mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="thumbnail">
                                    <img className="img-fluid img-pad" src={Food} alt="Bubble" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="content cont-reverse ">
                                    <h3 className="titles">Hotel Management System</h3>
                                    <p className="cont-reverse mb-3">Technology is making deep inroads into the hospitality industry, and hotels across the world are looking to use technology. </p>
                                    <Link to="/hotelmanagement">
                                        <Rb.Button variant="btn alg_btn">Read More</Rb.Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-17 list-unstyled">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Probubble1} alt="Bubble" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Probubble2} alt="Bubble" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Probubble3} alt="Bubble" />
                        </li>
                        <li className="shape shape-4">
                            <img className="img-fluid" src={Probubble4} alt="Bubble" />
                        </li>
                        <li className="shape shape-5">
                            <img className="img-fluid" src={Probubble5} alt="Bubble" />
                        </li>
                        <li className="shape shape-6">
                            <img className="img-fluid" src={Probubble6} alt="Bubble" />
                        </li>
                    </ul>
                </section>
                <Gototop/>
            </div>
        )
    
}
export default Product;


