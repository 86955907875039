import React from "react";
import {Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import * as Bi from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightDots, faComputer, faCalendarDays, faCalendarRange, faChartLine, faMicrochip, faAward,} from "@fortawesome/free-solid-svg-icons";
import Services from "./services";
import About from "./about";
import Selectus from "./assets/img/img.png";
import Phone from "./assets/img/phone-01.png";
import Icon1 from "./assets/img/icon-1.png";
import AboutImg from "./assets/img/about_img3.png";
import Line from "./assets/img/line-9.png";
import Line1 from "./assets/img/line-4.png";
import Line2 from "./assets/img/line-5.png";
import CirBig from "./assets/img/bubble-big.png";
import CirSmall from "./assets/img/bubble-small.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import HalfCir from "./assets/img/half-circle.png";
import Pro1 from "./assets/img/process1.png";
import Pro2 from "./assets/img/process2.png";
import Pro3 from "./assets/img/process3.png";
import Pro4 from "./assets/img/process4.png";
import TCir1 from "./assets/img/top-cir1.png";
import TCir2 from "./assets/img/top-cir2.png";
import TCir3 from "./assets/img/top-cir3.png";
import TCir4 from "./assets/img/top-cir4.png";
import TCir5 from "./assets/img/top-cir5.png";
import TCir6 from "./assets/img/top-cir6.png";
import TCir7 from "./assets/img/top-cir7.png";
import Select1 from "./assets/img/select1.png";
import Select2 from "./assets/img/select2.png";
import Select3 from "./assets/img/select3.png";
import Select4 from "./assets/img/select4.png";
import Select5 from "./assets/img/select5.png";
import Select6 from "./assets/img/select6.png";
import Chat from "./assets/img/chat-group.png";
import Lady from "./assets/img/laptop-poses.png";
import Boy from "./assets/img/bill-pay.png";
import Estbub1 from "./assets/img/est-bubble-12.png";
import Estbub2 from "./assets/img/est-bubble-16.png";
import Estbub3 from "./assets/img/est-bubble-13.png";
import Estbub4 from "./assets/img/est-bubble-14.png";
import Estbub5 from "./assets/img/est-bubble-17.png";
import Estbub6 from "./assets/img/est-bubble-15.png";
import Estbub7 from "./assets/img/est-bubble-18.png";
import Cl1 from "./assets/img/Cl-1.png";
import Cl2 from "./assets/img/Cl-2.png";
import Cl3 from "./assets/img/Cl-3.png";
import Cl4 from "./assets/img/Cl-4.png";
import Cl5 from "./assets/img/Cl-5.png";
import Cl6 from "./assets/img/Cl-6.png";
import Food from "./assets/img/pro1.png";
import Pawn from "./assets/img/pro2.png";
import Support from "./assets/img/pro3.png";
import Hospital from "./assets/img/pro4.png";
import Hr from "./assets/img/pro5.png";
import Retail from "./assets/img/pro6.png";
import Alpha from "./assets/img/alpha.png";
import Cloud from "./assets/img/cloud.png";
import Copy from "./assets/img/copy.png";
import Dev from "./assets/img/dev.png";
import SEO from "./assets/img/seo-pro.png";
import Mob from "./assets/img/mobile.png";
import MobApp from "./assets/img/mob-app.png";
import CusDev from "./assets/img/cus-dev.png";
import Plat1 from "./assets/img/plat1.png";
import Plat2 from "./assets/img/plat2.png";
import Plat3 from "./assets/img/plat3.png";
import Plat4 from "./assets/img/plat4.png";
import Plat5 from "./assets/img/plat5.png";
import Plat6 from "./assets/img/plat6.png";
import Plat7 from "./assets/img/plat7.png";
import Plat8 from "./assets/img/plat8.png";
import Plat9 from "./assets/img/plat9.png";
import Plat10 from "./assets/img/plat10.png";
import Plat11 from "./assets/img/plat11.png";
import Plat12 from "./assets/img/plat12.png";
import Plat13 from "./assets/img/plat13.png";
import Plat14 from "./assets/img/plat14.png";
import Plat15 from "./assets/img/plat15.png";
import Plat16 from "./assets/img/plat16.png";
import Plat17 from "./assets/img/plat17.png";
import Plat18 from "./assets/img/plat18.png";
import Plat19 from "./assets/img/plat19.png";
import Plat20 from "./assets/img/plat20.png";
import Plat21 from "./assets/img/plat21.png";
import Plat22 from "./assets/img/plat22.png";
import Plat23 from "./assets/img/plat23.png";
import Plat24 from "./assets/img/plat24.png";
import Plat25 from "./assets/img/plat25.png";
import Banner1 from "./assets/img/banner1.png";
import Banner2 from "./assets/img/banner2.png";
import Banner3 from "./assets/img/banner3.png";
import Banner4 from "./assets/img/banner4.png";
import Ser1 from "./assets/img/ser1.png";
import Ser2 from "./assets/img/ser2.png";
import Ser3 from "./assets/img/ser3.png"; 
import Work from "./assets/img/meto.png";
import WorkMob from "./assets/img/meto_m.png";
import Img from "./assets/img/admin.png";
import Info from "./assets/img/info.png"
import App_img from "./assets/img/home-mobile.png";
import New_tech from "./assets/img/new-tech.png";
import Perfection from "./assets/img/perfection.png";
import Smart from "./assets/img/smart.png";
import Time from "./assets/img/time.png";
import Reputation from "./assets/img/reputation.png";
import Port_gold from "./assets/img/port_gold.png";
import Cart from "./assets/img/cart.png";
import Account from "./assets/img/account.png";
import Hotel from "./assets/img/hotel.png";
import E_com from "./assets/img/e-commerce.png";
import Industrial from "./assets/img/industrial.png";
import Secure from "./assets/img/secure.png";
import Seo from "./assets/img/seo.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container,Row,Col,Carousel,Button,Tab,Nav,Image} from "react-bootstrap";
import Gototop from "./gototop";
// import {BsFillPersonPlusFill,BsGraphUp,BsHeartFill,BsGift} from "react-icons/bs";

const test = {
    loop:true,
    margin: 0,
    merge:true,
    center:true,
    responsiveclass:true,
    callbacks:true,
    autoplay: true,
    smartSpeed: 500,
    autoplay : true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  const home_banner = {
    loop:true,
    margin: 0,
    merge:true,
    center:true,
    responsiveclass:true,
    callbacks:true,
    autoplay: true,
    smartSpeed: 500,
    autoplay : true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
    },
  };
  const testimony = {
    loop:true,
    margin: 0,
    merge:true,
    center:true,
    responsiveclass:true,
    callbacks:true,
    autoplay: true,
    smartSpeed: 500,
    autoplay : true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
class home extends React.Component
{
   
    constructor()
    {
        super();
        this.state={
            heading:["Digital Experiences","Software Development","We understand your requirement and provide quality works."],
            select_us_title:["Why Select Us ?","We understand your requirement and provide quality works","Select"],
            work:["Our Work Methodology","We understand your requirement and provide quality works."," Work"],
            portfolio:["Portfolio","We understand your requirement and provide quality works.","Portfolio"],
            services:["Our Services","We understand your requirement and provide quality works.","  Service"],
            testi:["Testimonials","We understand your requirement and provide quality works.","    Review"]
        }
        
    }
    render(){
        return(
            <div> 
                <section  className="">
                    <Container className="about_banner">
                        <OwlCarousel className='owl-theme banner_owl' loop margin={10} nav {...home_banner} controls={false}>
                            <div className='slide-item  caro_content'>
                                <Row className="banner_height_set align-items-center ">
                                    <Col sm={12} md={6} lg={6} className="">
                                        <div className="home_align banner_para_align">
                                            <h2 className="home_h2 ">Customised Mobile Apps</h2>
                                            <p className="">Digital Experiences.</p>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <Image fluid src={Banner1} alt="about_img" className="about_img_align1"/>
                                    </Col>
                                </Row>
                            </div>
                            <div className='slide-item  caro_content'>
                                <Row className="banner_height_set align-items-center ">
                                    <Col sm={12} md={6} lg={6} className="">
                                        <div className="home_align banner_para_align">
                                            <h2 className="home_h2 ">Web Design</h2>
                                            <p className="">Digital Experiences.</p>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <Image fluid src={Banner4} alt="about_img" className="about_img_align1"/>
                                    </Col>
                                </Row>
                            </div>
                            <div className='slide-item'>
                                <div className='slide-item  caro_content '>
                                    <Row className="banner_height_set  align-items-center ">
                                        <Col sm={12} md={6} lg={6} className="">
                                            <div className="home_align banner_para_align">
                                                <h2 className="home_h2 "> Software Development</h2>
                                                <p className="">Digital Experiences.</p>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={6}>
                                         
                                            <Image fluid src={Banner2} alt="about_img" className="about_img_align1"/>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className='slide-item'>
                                <div className='slide-item  caro_content '>
                                    <Row className="banner_height_set  align-items-center ">
                                        <Col sm={12} md={6} lg={6} className="">
                                            <div className="home_align banner_para_align">
                                                <h2 className="home_h2 "> Search Optimization (SEO)</h2>
                                                <p className="">Digital Experiences.</p>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={6}>
                                            <Image fluid src={Banner3} alt="about_img" className="about_img_align1"/>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                           
                        </OwlCarousel>
                    </Container>

                    <ul className="list-unstyled shape-group-21">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={TCir1} alt="Bubble" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={TCir2} alt="Bubble" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={TCir3} alt="Bubble" />
                        </li>
                        <li className="shape shape-4">
                            <img className="img-fluid" src={TCir4} alt="Bubble" />
                        </li>
                        <li className="shape shape-5">
                            <img className="img-fluid" src={TCir5} alt="Bubble" />
                        </li>
                        <li className="shape shape-6">
                            <img className="img-fluid" src={TCir6} alt="Bubble" />
                        </li>
                        <li className="shape shape-7">
                            <img className="img-fluid" src={TCir7} alt="Bubble" />
                        </li>
                    </ul>
                  
                </section>
                <section className="portfolio section section-padding-equal project-column-4 gradient">
                    <div className="container">
                        <div className="section-heading heading-left mb--40 ">
                            <div className="subtitle">Why Select Us?</div>
                            <h2 className="title">We understand your<br/> requirement and provide <br/>quality works.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto select-box">
                                <div className="product-grid service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Select2} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Business&nbsp;Growth 
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">We are passionate about providing solutions that help our clients grow and benefit from the ever-growing world of IT industry. We continue to analyse clients' business rules and make improvements to the applications as needed.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto select-box">
                                <div className="product-grid service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Select6} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Latest&nbsp;Technologies 
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Our unique competence in various technologies enables us to deliver integrated, scalable, robust solutions with the optimal cost and performance ratio. We constantly conduct research on latest technology products to meet the ever-growing needs of our customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto select-box">
                                <div className="product-grid service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Select3} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Ultimate&nbsp;Perfection
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">We never compromise on the quality of our services. Our dedicated quality assurance team monitors project activities at all development phases and ensures defect prevention, project risk mitigation and high-quality results, which shape our product with ultimate perfection.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto select-box">
                                <div className="product-grid service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Select5} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Smart&nbsp;Experience
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Choosing our company as your preferred IT partner empowers you with the flexibility to bring on highly-skilled, experienced and knowledgeable IT experts to implement complex projects successfully.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto select-box">
                                <div className="product-grid service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Select4} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Strict&nbsp;Deadline
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">We have a proven track record of hitting deadlines, staying on budget, and having a pro-active approach to client relations. By keeping our promises and exceeding client expectations we seek to provide a service that can be trusted and provides value for money.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto select-box">
                                <div className="product-grid service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Select1} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Reputed&nbsp;Company
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">We are one of the reputed companies who has built a good rapport in the field of IT service. Through over 13+ years’ experience in delivering complex IT solutions successfully to customers ranging from big enterprises to mid-sized and small business, we have proved to be the appropriate and reliable partner.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-7">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Cir1} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Cir2} alt="Circle" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Cir3} alt="Circle" />
                        </li>
                    </ul>
                </section>
                <section className="section section-padding-equal bg-color-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-us">
                                    <div className="section-heading heading-left mb-0">
                                        <span className="subtitle">About</span>
                                        <h2 className="title mb--40">We do design, code & develop.</h2>
                                        <p>TAGCS is a diversified IT services company. We offer a comprehensive variety of IT services and solutions that can satisfy our clients in all ways.
TAGCS have been creating striking, affordable, dynamic and practical software, web & Mobile App solutions for organizations of all kinds. TAGCS creates technology-empowered IT solutions and services by combining industry leading processes and technologies that deliver business values to clients. We started our journey by developing customer software app and now we are into SaaS model (Software as a Service)- 
Single platform multiple business application with cost effective, Data Security and is accessible from anywhere in the world.
</p>
                                        <Link  to="/about" className= "btn cont-btn nav_border"> Read more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div>
                                    <img className="cont-about-img img-fluid" src={AboutImg} alt="about" title="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-6">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={HalfCir} alt="Circle" />
                        </li>
                        {/* <li className="shape shape-2">
                            <img className="img-fluid" src={Line1} alt="Circle" />
                        </li> */}
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Line2} alt="Circle" />
                        </li>
                    </ul>
                </section>
                
                <section className="service section-padding-equal service_bg section">
                    <div className="container ">
                        <div className="section-heading text-start mb-3">
                            <p className="subtitle">Our Services</p>
                            <h2 className="title">We understand your requirement and provide quality works.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 mx-auto services">
                                <div className="services-grid">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Dev} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            customised Software Development 
                                        </h5>
                                        <div className="floatLeft">
                                        <p className="">Our initial development started with customised software app and now we are into SaaS model (Software as a Service) - Single platform.</p>
                                        <Link  to="/services" className= "more-btn"> Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 mx-auto">
                                <div className="services-grid">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Mob} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            customised Mobile Apps
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Grow your business with performance-optimized; salable mobile apps developed using TAGCS's expertise</p>
                                            <Link  to="/services" className= "more-btn"> Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 mx-auto">
                                <div className="services-grid">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Cloud} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Information Architecture 
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">We believe that Great design with well-organized contents will yield a successful website. Our team will get you the best</p>
                                            <Link  to="/services" className= "more-btn"> Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 mx-auto">
                                <div className="services-grid">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={SEO} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Search&nbsp;Optimization (SEO)
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">This is the backbone for searching your business in millions. We help you choose the words and have a best index for the search</p>
                                            <Link  to="/services" className= "more-btn"> Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 mx-auto">
                                <div className="services-grid">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Copy} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Copywriting 
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Worthy websites, require worthy writing. Content Writing for the web can be especially complicated commerce, but you</p>
                                            <Link  to="/services" className= "more-btn"> Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12 mx-auto">
                                <div className="services-grid">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Alpha} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                            Tactile&nbsp;Graphics
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Tactile graphics are a means of conveying non-textual information to people who are blind or visually impaired, and may include</p>
                                            <Link  to="/services" className= "more-btn"> Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <ul className="list-unstyled shape-group-10">
                            <li className="shape shape-1">
                                <img className="img-fluid" src={Line} alt="Circle" />
                            </li>
                            <li className="shape shape-2">
                                <img className="img-fluid" src={CirBig} alt="Circle" />
                            </li>
                            <li className="shape shape-3">
                                <img className="img-fluid" src={CirSmall} alt="Circle" />
                            </li>
                        </ul>
                </section>
                <section className="portfolio section section-padding-equal project-column-4">
                    <div className="container">
                        <div className="section-heading heading-left mb--40 ">
                            <div className="subtitle">Our Portfolio</div>
                            <h2 className="title">Some of our finest<br /> work</h2>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a target="blank" href="http://cobota.in/">
                                            <img src={Cl1} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">COBOTA</div>
                                        <span className="subtitle">
                                            <span>E-Commerce</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a target="blank" href="http://foresightchennai.com/">
                                            <img src={Cl2} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">FORESIGHT</div>
                                        <span className="subtitle">
                                            <span>Industrial</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a target="blank" href="http://newmoorthycafe.com/">
                                            <img src={Cl3} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">NEW MOORTHY CAFE</div>
                                        <span className="subtitle">
                                            <span>Hotel</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a target="blank" href="https://studysmart.sg/">
                                            <img src={Cl6} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">Imperial Edu Tech</div>
                                        <span className="subtitle">
                                            <span>Education</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={Cl4} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">ANDERSON DIAGNOSTICS & LABS</div>
                                        <span className="subtitle">
                                            <span>Hospital</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                                <div className="project-grid mx-auto">
                                    <div className="thumbnail">
                                        <a href="/">
                                            <img src={Cl5} className="" alt="icon" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <div className="title">VIKATAN</div>
                                        <span className="subtitle">
                                            <span>Media</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-7">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Cir1} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Cir2} alt="Circle" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Cir3} alt="Circle" />
                        </li>
                    </ul>
                </section>
                <section className="section section-padding-equal bg-color-light">
                    <div className="container">
                        <div className="product">
                            <div className="section-heading text-start mb-3">
                                <p className="subtitle">Our Products</p>
                                <h2 className="title">We understand business requirements and provide quality product</h2>
                            </div>
                            <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="product-grid h-400 service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Retail} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                        Retail&nbsp;Application
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">To conquest the digital combat, retailers must essentially rethink how applications should be built and deployed</p>
                                            <Link  to="/products" className= "more-btn"> Read more<Bi.BsArrowRightShort className="more-btn read_icon"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="product-grid h-400 service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Pawn} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                        Pawn&nbsp;Broking Application
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Pawn Broking application is software for Pawn Broking, Pawn Shop, Pawn Broker, Jewel Loan Finance and Gold Finance Companies</p>
                                            <Link  to="/products" className= "more-btn"> Read more<Bi.BsArrowRightShort className="more-btn read_icon"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="product-grid h-400 service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Hospital} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                        Hospital&nbsp;Management System
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Hospital Management System is an integrated end-to-end web based software application intended to make the administrative</p>
                                            <Link  to="/products" className= "more-btn"> Read more<Bi.BsArrowRightShort className="more-btn read_icon"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="product-grid h-400 service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Hr} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                        Human&nbsp;Resource Management System
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Our TAGCS HRMS is designed and suitable for every businesses. This powerful and easy to use HRMS software allows</p>
                                            <Link  to="/products" className= "more-btn"> Read more<Bi.BsArrowRightShort className="more-btn read_icon"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="product-grid h-400 service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Food} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                        Hotel&nbsp;Management System
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Technology is making deep inroads into the hospitality industry, and hotels across the world are looking to use technology</p>
                                            <Link  to="/products" className= "more-btn"> Read more<Bi.BsArrowRightShort className="more-btn read_icon"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="product-grid h-400 service-style-2">
                                    <div className="content">
                                        <div className="thumbnail floatLeft">
                                            <img className="img-fluid" src={Support} alt="icon" />
                                        </div>
                                        <h5 className="title my-sm-2 py-sm-2">
                                        Support&nbsp;Ticketing Tools
                                        </h5>
                                        <div className="floatLeft">
                                            <p className="">Ticketing Management aids automate help desk tasks and saves ample of time for IT technicians and support Employees</p>
                                            <Link  to="/products" className= "more-btn"> Read more<Bi.BsArrowRightShort className="more-btn read_icon"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-6">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={HalfCir} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Line1} alt="Circle" />
                        </li>
                    </ul>
                </section>   

                <section className="service section-padding-equal service_bg section">
                    <div className="container ">
                        <div className="section-heading text-start mb-3">
                            <p className="subtitle">Our Development Platform</p>
                            <h2 className="title">TAGCS team always explore their skills with latest technologies</h2>
                        </div>
                        <div className="slider">
                            <div class="slide-track">
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat1} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat2} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat3} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat4} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat5} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat6} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat7} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat8} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat9} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat10} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat11} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat12} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat13} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat14} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat15} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat16} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat17} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat18} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat19} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat20} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat21} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat22} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat23} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat24} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat25} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat1} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat2} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat3} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat4} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat5} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat6} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat7} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat8} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat9} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat10} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat11} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat12} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat13} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat14} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat15} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat16} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat17} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat18} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat19} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat20} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat21} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat22} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat23} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat24} alt="Circle" />
                                </div>
                                <div class="slide brand-grid">
                                    <img className="img-fluid" src={Plat25} alt="Circle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
                <section className="work_sec_align section-padding-equal">
                    <Container>
                        <div className="col-lg-8">
                            <div className="about-us">
                                <div className="section-heading heading-left mb-0">
                                    <span className="subtitle">Our Work Methodology</span>
                                    <h2 className="title mb--40">We understand client’s expectation and provide on time deliverables</h2>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <div class="ps-timeline-sec">
                        <div class="container">
                            <ol class="ps-timeline">
                                <li className="text-center">
                                    <div class="img-handler-top">
                                        <img className="img-fluid" src={Pro1} alt="Circle" />
                                        <h3 className="text-center"><b>Discover</b></h3>
                                    </div>
                                    <div class="ps-bot">
                                        <p class="text-left">Learn<br />Analyze<br />Explore</p>
                                    </div>
                                    <span class="ps-sp-top">01</span>
                                </li>
                                <li>
                                    <div class="img-handler-bot">
                                        <img className="img-fluid" src={Pro2} alt="Circle" />
                                        <h3 className="text-center"><b>Design</b></h3>
                                    </div>
                                    <div class="ps-top">
                                        <p class="text-left">Ideate<br />Test<br />Prototype</p>
                                    </div>
                                    <span class="ps-sp-bot">02</span>
                                </li>
                                <li>
                                    <div class="img-handler-top">
                                        <img className="img-fluid" src={Pro3} alt="Circle" />
                                        <h3 className="text-center"><b>Develop</b></h3>
                                    </div>
                                    <div class="ps-bot">
                                        <p class="text-left">Build<br />Pilot<br />Validation</p>
                                    </div>
                                    <span class="ps-sp-top">03</span>
                                </li>
                                <li>
                                    <div class="img-handler-bot">
                                        <img className="img-fluid" src={Pro4} alt="Circle" />
                                        <h3 className="text-center"><b>Deliver</b></h3>
                                    </div>
                                    <div class="ps-top">
                                        <p class="text-left">Launch<br />Measure<br />Maintain</p>
                                    </div>
                                    <span class="ps-sp-bot">04</span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </section>

                {/* <section className="work_sec_align section-padding-equal">
                    <Container>
                        <div className="col-lg-8">
                            <div className="about-us">
                                <div className="section-heading heading-left mb-0">
                                    <span className="subtitle">Our Work Methodology</span>
                                    <h2 className="title mb--40">We understand your requirement and provide quality works.</h2>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Container>
                        <div className="">
                            <Image fluid src={Work} alt="work_img" className="d-none d-md-block d-lg-block"/>
                            <Image fluid src={WorkMob} alt="work_img" className="d-block d-md-none d-lg-none"/>
                        </div>
                    </Container>
                </section> */}
                <section className="section call-to-action-area">
                        <div className="container">
                            <div className="call-to-action est">
                                <div className="section-heading heading-light">
                                    <span className="subtitle">Let's Work Together</span>
                                    <h2 className="title">Wish to connect?</h2>
                                    <Link  to="/contact" className= "axil-btn btn-large btn-fill-white">Reach us</Link>
                                    {/* <a className="axil-btn btn-large btn-fill-white" href="/contact">Estimate Project</a> */}
                                </div>
                                <div className="thumbnail">
                                    <div className="larg-thumb" data-sal="zoom-in" data-sal-duration="600" data-sal-delay="100">
                                        <img className="img-fluid" src={Chat} alt="Circle" />
                                    </div>
                                    {/* <ul className="list-unstyled small-thumb">
                                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="800" data-sal-delay="400">
                                            <img className="img-fluid" src={Lady} alt="Circle" />
                                        </li>
                                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="800" data-sal-delay="300">
                                            <img className="img-fluid" src={Boy} alt="Circle" />
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <ul className="list-unstyled shape-group-9">
                            <li className="shape shape-1">
                                <img className="img-fluid" src={Estbub1} alt="Circle" />
                            </li>
                            <li className="shape shape-2">
                                <img className="img-fluid" src={Estbub2} alt="Circle" />
                            </li>
                            <li className="shape shape-3">
                                <img className="img-fluid" src={Estbub3} alt="Circle" />
                            </li>
                            <li className="shape shape-5">
                                <img className="img-fluid" src={Estbub5} alt="Circle" />
                            </li>
                            <li className="shape shape-6">
                                <img className="img-fluid" src={Estbub6} alt="Circle" />
                            </li>
                            <li className="shape shape-7">
                                <img className="img-fluid" src={Estbub7} alt="Circle" />
                            </li>
                        </ul>
                </section>               
                <section className="section section-padding-equal bg-blue">
                    <div className="container">
                        <div className="product">
                            <div className="section-heading text-start mb-3">
                                <p className="subtitle">Testimonial</p>
                                <h2 className="title">Customer Reviews.</h2>
                            </div>
                            <div className="row">
                                <Col md={8} className="mx-auto">
                                    <OwlCarousel className='owl-theme owl_align testimonial' loop margin={10} nav {...testimony}>
                                        <div className='slide-item sm-padding mx-md-2 py-3'>
                                            <div className=" testi_box_align  caro_content p-5">
                                                <p className="font-size-14 text-center"> TAGCS is dynamic customer focused company. I am impressed by their tenacity and commitment.</p>
                                                <h3 className="testi_title"> - Dr. Srinivasa Raman,<br /> <span>Anderson Diagnostics</span></h3>
                                            </div>
                                        </div>
                                        <div className='slide-item sm-padding mx-md-2 py-3'>
                                            <div className=" testi_box_align caro_content p-5">
                                                <p className="font-size-14 text-center">TAGCS, understanding our needs perfectly and suits the product to meet our requirements. Most often they come out with solutions surpassing our expectations. They are prompt in service, simply put “Nothing is Impossible” for them.</p>
                                                <h3 className="testi_title"> -  Mr. Shivasubramanian, <br />  <span>   Sristi builders & Developers</span></h3>
                                            </div>
                                        </div>
                                        <div className='slide-item sm-padding mx-md-2 py-3'>
                                            <div className=" testi_box_align caro_content p-5">
                                                <p className="font-size-14 text-center">This is to certify that me Krishna Creation are working with TAGCS from past one year, they had made on Computer Software (Business) for our better performance this company staff our very co-operative, loyal and good by their personal performance about the MD of TAGCS, he is such a kind person in nature and technical I his business.</p>
                                                <h3 className="testi_title"> - Mr. M. Manish,    <br />  <span>   Krishna Creation</span></h3>
                                            </div>
                                        </div>
                                        <div className='slide-item sm-padding mx-md-2 py-3'>
                                            <div className=" testi_box_align  caro_content p-5">
                                                <p className="font-size-14 text-center"> Team at TAGCS is excellent at understanding need of the client and takes sincere effort to propose right solutions. Converting day to day business into system languages is done here to perfection. Understanding dynamic changes in multiple dimensions from TAGCS needs to be acknowledged. Thanks to TAGCS's CSR effort with training Agaram students, placing them at their organization and developing a portal to automate Agaram's Process.</p>
                                                <h3 className="testi_title">  - Mr. S. Ganesh, <br />  <span>  Agaram Foundation</span></h3>
                                            </div>
                                        </div>
                                        <div className='slide-item sm-padding mx-md-2 py-3'>
                                            <div className=" testi_box_align caro_content p-5">
                                                <p className="font-size-14 text-center">Greetings! You have done a excellent work which shows your involvement and hard work towards with our website creation. It fulfills our dream through your TAGCS's people who i have great innovations that reflects in our website. Thanks to TAGCS. We are expecting your cooperation for our future plans. Wish you for great success with your guidelines to achieve new plans in TAGCS.</p>
                                                <h3 className="testi_title"> Dr. M. Selvakumar B.O.T., M.Sc. (Psy.,)  <br />   <span>  - Vidial Groups</span></h3>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </Col>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-6">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={HalfCir} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Line1} alt="Circle" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Line2} alt="Circle" />
                        </li>
                    </ul>
                </section>
                <Gototop/>
                {/* var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/6164103c86aee40a5735f5eb/1fhndurf5';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
                })(); */}
            </div>
            
        );
    }

}
export default home;