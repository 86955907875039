import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./home";
import Header from "./header";
import About from "./about";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Services from "./services";
import Portfolio from "./portfolio";
import Product from "./product";
import Career from "./careers";
import Contact from "./contactus";
import Footer from "./footer";
import Retailapplication from "./retailapplication";
import Pawnbroking from "./pawnbroking";
import Humanresourse from "./humanresourse";
import Hotelmanagement from "./hotelmanagement";
import Hospitalmanagement from "./hospitalmanagement";
import Supportticket from "./supportticket";
// import Test from "./test";
class Holding extends React.Component
{
    render(){
        return(
            
                <div>
                   <BrowserRouter>
                    <Routes>
                            <Route path="/" element={<Header />}>
                            <Route path="/"element={<Home />} />
                            <Route path="about"element={<About/>}/>
                            <Route path="services"element={<Services/>}/>
                            <Route path="portfolio"element={<Portfolio/>}/>
                            <Route path="products"element={<Product/>}/>
                            <Route path="careers"element={<Career/>}/>
                            <Route path="contact"element={<Contact/>}/>
                            <Route path="pawnbroking"element={<Pawnbroking/>}/>
                            <Route path="humanresourse"element={<Humanresourse/>}/>
                            <Route path="hotelmanagement"element={<Hotelmanagement/>}/>
                            <Route path="hospitalmanagement"element={<Hospitalmanagement/>}/>
                            <Route path="supportticket"element={<Supportticket/>}/>
                            <Route path="retailapplication"element={<Retailapplication/>}/>
                        </Route>
                    </Routes>
                    <Footer/>
                </BrowserRouter>
                </div>
           
        )
    }
    
}
export default Holding