import React from "react";
import {Link} from "react-router-dom";
import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Bi from "react-icons/bs";
import "./home.css";
import TCir1 from "./assets/img/top-cir1.png";
import Food from "./assets/img/s-food.png";
import Pawn from "./assets/img/s-pawn.png";
import Support from "./assets/img/s-support.png";
import Hospital from "./assets/img/s-medical.png";
import Hr from "./assets/img/s-hr.png";
import CusDev from "./assets/img/s-retail.png";
import Bub1 from "./assets/img/S-bubble1.png";
import Bub2 from "./assets/img/S-bubble2.png";
import Bub3 from "./assets/img/S-bubble3.png";
import Port_img from "./assets/img/port.png";
import Cl1 from "./assets/img/portfolio/Cl-1.png";
import Cl2 from "./assets/img/portfolio/Cl-2.png";
import Cl3 from "./assets/img/portfolio/Cl-3.png";
import Cl4 from "./assets/img/portfolio/Cl-4.png";
import Cl5 from "./assets/img/portfolio/Cl-5.png";
import Cl6 from "./assets/img/portfolio/Cl-6.png";
import Cl7 from "./assets/img/portfolio/Cl-7.png";
import Cl8 from "./assets/img/portfolio/Cl-8.png";
import Cl9 from "./assets/img/portfolio/Cl-9.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Tab from "./assets/img/tab2.png";
import Portimg_1 from "./assets/img/port_img1.png";
import Portimg_2 from "./assets/img/port_img2.png";
import Portimg_3 from "./assets/img/port_img3.png";
import Portimg_4 from "./assets/img/port_img4.png";
import Portimg_5 from "./assets/img/port_img5.png";
import Portimg_6 from "./assets/img/port_img6.png";
import Portimg_7 from "./assets/img/port_img7.png";
import Portimg_8 from "./assets/img/port_img8.png";
import Portimg_9 from "./assets/img/port_img9.png";
import Portimg_10 from "./assets/img/port_img10.png";
import Portimg_11 from "./assets/img/port_img11.png";
import Portimg_12 from "./assets/img/port_img12.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";

class pawnbroking extends React.Component
{
    render(){
       
        return(
            <div>
                <section  className="breadcrum-area breadcrumb-banner single-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left">
                                    <h1 className="title h2">Pawn Broking Application.</h1>
                                    <p>A quick view of industry specific problems solved with design by the awesome team at Keystroke.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail">
                                    <img className="img-fluid" src={Pawn} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Bub1} alt="icon" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Bub2} alt="icon" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Bub3} alt="icon" />
                        </li>
                    </ul>  
                </section>
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-heading heading-left mb-0">
                                    <h3 className="title">Pawn Broking Application</h3>
                                </div>
                                <p>Pawn Broking application is software for Pawn Broking, Pawn Shop, Pawn Broker, Jewel Loan Finance and Gold Finance Companies.</p>
                                <p>TAGCS has developed this application by means of the latest technologies and significantly expands the functionality of previous versions to newer ones.</p>
                                <p>TAGCS software application is comprised of a base system and key functional areas that offer a wide number of different Loan and Interest scheme types.</p>
                                <p>It is a comprehensive yet user friendly application.</p>
                                <p>This pawn broking application is available in both single user and multi-user versions. This will save your time and money by handling all the necessary features.</p>
                                <div className="section-heading heading-left mb-0">
                                    <h4 className="title">Business Benefits</h4>
                                </div>
                                <p>Pawn Broking Application maximizes the profit for the business and reduces loss to the max. It helps in reducing manual work and increases a relaxed business time.</p>
                                <p>The utilization of this pawn broking application provides improved profitability, good productivity, efficiency, attractiveness and competitiveness.</p>
                                <p>This application helps us track the consumer information in one place. We can make speedy decisions and also find key information fast.</p>
                                <p>This application helps you receive an improved consumer satisfaction and global improvement in business reporting and management.</p>
                                <p>It enables interest calculation and keeps you away from income loss and man-made error.</p>
                                <a className="axil-btn btn-fill-primary" href="https://tagit.store/pawnbroker-pricing.html" target="blank">Get it Now</a>
                            </div>
                            <div className="col-lg-6 offset-xl-1">
                                <div className="why-choose-us">
                                    <div className="section-heading heading-left">
                                    <table className="table striped">
                                                <thead className="thear_align">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Particulars</th>
                                                        <th>S</th>
                                                        <th>G</th>
                                                        <th>D</th>
                                                        <th>P</th>
                                                        <th>T</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Configuration</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Customer Registration</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Loan Enroll</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Loan Close</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Basic Report</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Extended Report</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Exist Loan Update</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Day Update</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>User Creations</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>User Rights</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>PA Account</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>Notice Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>13</td>
                                                        <td>Log</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>14</td>
                                                        <td>Web Cam For Customer Registration</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>15</td>
                                                        <td>Biometric Authentications</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>16</td>
                                                        <td>Barcode Process For Loan Receipt</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>17</td>
                                                        <td>Dashboard Alert For Over Due Loans</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>18</td>
                                                        <td>SMS Alerts To Customers</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>19</td>
                                                        <td>Group Loan Close & Renewal Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>20</td>
                                                        <td>Hypothecation Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>21</td>
                                                        <td>Online Core Integrations</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Gototop/>
            </div>
        )
    }
}
export default pawnbroking