import React from "react";
import { useState } from 'react';
import { FloatingLabel, Form, Alert } from 'react-bootstrap';
import * as Rb from "react-bootstrap";
import * as Bi from "react-icons/bs";
import * as Gr from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Contact from "./assets/img/contactus.png";
import Bubble1 from "./assets/img/cont-bubble1.png";
import Bubble2 from "./assets/img/cont-bubble2.png";
import Bubble3 from "./assets/img/cont-bubble3.png";
import Gototop from "./gototop";
import axios from "axios";

const Contactus = () =>{ 

    const [username, setUsername] = useState('');
    const [usernameErr, setUsernameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [phoneNumberInput, setPhoneNumberInput] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [description,setDescription]=useState("");
    const [descriptionError,setDescriptionError]=useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');


    const handleUserName = (value) => {
        setUsername(value);
        if (value === '') {
          setUsernameErr('Please Enter the Username');
        } else {
          setUsernameErr('');
        }
      };
      
      const handleEmail = (value) => {
        setEmail(value);
        const emailRegex = /^\w+([-]?\w+)@\w+([-]?\w+)(\.\w{2,})+$/;
        if (value === '') {
          setEmailErr('Please enter your email.');
        } else if (!emailRegex.test(value)) {
          setEmailErr('Please enter a valid email.');
        } else {
          setEmailErr('');
        }
      };
      
      const handlePhoneNumberChange = (value) => {
        setPhoneNumberValue(value);
        const phNoRegex = /^\d*\.?\d*$/;
        if (value === '') {
          setPhoneNumberError('Please enter your Phone Number.');
        } else if (!phNoRegex.test(value)) {
          setPhoneNumberError('Please enter Valid Mobile Number.');
        } else {
          setPhoneNumberError('');
        }
      };
      
      const handleDescription = (value) => {
        setDescription(value);
        if (value === '') {
            setDescriptionError('Please Enter the Need.');
        } else {
            setDescriptionError('');
        }
      };

      const handleSumbit = async (e) => {
        e.preventDefault();
      
        // Call the respective handlers for field validation
        handleUserName(username);
        handleEmail(email);
        handlePhoneNumberChange(phoneNumberValue);
        handleDescription(description);
      // console.log(username);
      // console.log(email);
      // console.log(phoneNumberValue);
      // console.log(description);

     
        // Check if any validation errors exist
        // if (usernameErr || emailErr || descriptionError || phoneNumberError) {
        //   // Do not proceed with form submission if there are errors
        //   console.error('Please fill in all the required fields correctly.');
        //   // setShowAlert(false);
        //   // return;
        // }else{
        //   setShowAlert(false);
        // }
        // return;
        if (username || email || phoneNumberValue || description) {
          // If no validation errors, proceed with sending the form data to the server
          const formData = new FormData();
          formData.append('username', username);
          formData.append('email', email);
          formData.append('phoneNumber', phoneNumberValue);
          formData.append('description', description);
        
          // Send the form data to the server
          try {
            const response = await axios.post('https://tagcs.net:3002/contactus', formData);
            // Handle the server response if needed
            console.log('sent successfully!', response.data);
            setAlertMessage('Thank you for reaching out to us. We will get back to you soon.');
            setShowAlert(true);
          } catch (error) {
            // Handle any error that occurs during form submission
            console.error('Error submitting :', error);
            // setShowAlert(false);
          }
        }
        setUsername("");
        setEmail("");
        setPhoneNumberValue("");
        setDescription("");
      };
      


   
        return(
        <div>
            <section  class="bg-img">
                <Rb.Container className="about_banner">
                    <Rb.Row className="banner_height_set align-items-center ">
                        <Rb.Col sm={12} md={6} lg={6} className="col_align">
                            <div className="banner_para_align">
                                <p className="about_head_para">CONTACT US</p>
                                <h2 className="about_h2">GET IN TOUCH</h2>
                            </div>
                        </Rb.Col>
                        <Rb.Col sm={12} md={6} lg={6}>
                            <Rb.Image src={Contact} alt="services_img" className="about_img_align"/>
                        </Rb.Col>
                     </Rb.Row>
                </Rb.Container>
            </section>
            <section class="section section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-6 my-auto">
                            <div class="contact-form-box shadow-box mb--30">
                                <h3 class="title">Enquiry</h3>
                                <div>
                                 <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        onBlur={(e) => handleUserName(e.target.value)} // Call validation function on blur
                                        />
                                        <span className="text-danger">{usernameErr}</span><br></br>
                                        <Form.Label className="mt-3">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={(e) => handleEmail(e.target.value)} // Call validation function on blur
                                            />
                                            <span className="text-danger">{emailErr}</span><br></br>
                                        <Form.Label className="mt-3">Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            maxLength={10}
                                            value={phoneNumberInput ? phoneNumberValue : ''}
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                const manipulatedValue = input.replace(/[^\d]/g, '').replace(/^0[^.]/, '0');
                                                setPhoneNumberValue(manipulatedValue);
                                                setPhoneNumberInput(input);
                                            }}
                                            onBlur={(e) => handlePhoneNumberChange(e.target.value)} // Call validation function on blur
                                            />
                                            <span className="text-danger">{phoneNumberError}</span><br></br>
                                    </Form.Group>
                                    <p className="form-label">How can we help you?</p>
                                    <FloatingLabel controlId="floatingTextarea2" label="" className="mt-1">
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '100px' }}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onBlur={(e) => handleDescription(e.target.value)} // Call validation function on blur
                                        />
                                        </FloatingLabel>
                                        <span className="text-danger">{descriptionError}</span>
                                </Form>
                                <div className="mt-3">
                                <button type="submit" class="axil-btn btn-fill-primary btn-fluid btn-primary"  name="submit-btn" onClick={handleSumbit}>Submit</button>
                                </div>
                                </div>
                                {showAlert && (
                                <Alert variant="success" onClose={() => setShowAlert(false)} dismissible delay={3000} className="mt-2">
                                {alertMessage}
                                </Alert>
                                )}
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 offfset-xl-2">
                            <div class="contact-info mb--30 mb_md--30 mt_md--0">
                                <h4 class="title">Registered Office</h4>
                                <p>Ganesh Enclave, No 40C,Murugan Kovil Street, 1st Floor,Vivekananda Nagar Main Road, Near Senthil Nagar Signal, Kolathur, Chennai- 600099, Tamilnadu, India</p>
                                <h4 class="phone-number mb-2"><Bi.BsPhone className="contacts_icon me-3"/>+91 95006 99100</h4>
                                <h4 class="phone-number mb-2"><Bi.BsTelephone className="contacts_icon me-3"/>+91 4144 225522</h4>
                                <h4 class="phone-number"><Bi.BsEnvelope className="contacts_icon me-3"/> info@tagcs.in</h4>
                            </div>
                            <div class="contact-info mb--30">
                                <h4 class="title">Branch Office</h4>
                                <p>BALAMBIGAI TOWERS, 30/24, 2nd cross, Gandhi Nagar, Chidambaram - 608001, Cuddalore District, Tamilnadu, India</p>
                                <p>BALAMBIGAI TOWERS, 30/24, 2nd cross, Gandhi Nagar, Chidambaram - 608001, Cuddalore District, Tamilnadu, India</p>
                                <h4 class="phone-number mb-2"><Bi.BsPhone className="contacts_icon me-3"/>+91 97511 99100</h4>
                                <h4 class="phone-number mb-2"><Bi.BsTelephone className="contacts_icon me-3"/>+91 44 43805522</h4>
                                <h4 class="phone-number"><Bi.BsEnvelope className="contacts_icon me-3"/> info@tagcs.in</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <h3>Registered Office</h3>
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.5818085779415!2d80.19807087580476!3d13.12565801148042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265309298be21%3A0x1f723626149f2442!2sGANESH%20ENCLAVE!5e0!3m2!1sen!2sin!4v1690621555794!5m2!1sen!2sin"
                              width="400"
                              height="400"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Google Map"
                            ></iframe>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <h3>Branch Office</h3>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.516564470784!2d78.6966242845442!3d10.848259930305169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf5cd25e57087%3A0xd5c643697763ded6!2sSree%20Ragaventhara%20Nestle%2C%20Thiruvannaikaval%20to%20Kallanai%20Rd%2C%20Srirangam%2C%20Mambazhasalai%2C%20Tiruchirappalli%2C%20Tamil%20Nadu%20620005!5e0!3m2!1sen!2sin!4v1690621611223!5m2!1sen!2sin"
                          width="400"
                          height="400"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="Google Map"
                        ></iframe>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-4"></div> */}
                    </div>
                </div>
                <ul class="list-unstyled shape-group-12">
                    <li class="shape shape-1">
                        <img class="img-fluid" src={Bubble1} alt="Bubble" />
                    </li>
                    <li class="shape shape-2">
                        <img class="img-fluid" src={Bubble2} alt="Bubble" />
                    </li>
                    <li class="shape shape-3">
                        <img class="img-fluid" src={Bubble3} alt="Bubble" />
                    </li>
                </ul>
            </section>
            <Gototop/>
        </div>
        )

};
export default Contactus;