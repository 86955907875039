import React from "react";
import {Link} from "react-router-dom";
import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Bi from "react-icons/bs";
import "./home.css";
import TCir1 from "./assets/img/top-cir1.png";
import Food from "./assets/img/s-food.png";
import Pawn from "./assets/img/s-pawn.png";
import Support from "./assets/img/s-support.png";
import Hospital from "./assets/img/s-medical.png";
import Hr from "./assets/img/s-hr.png";
import CusDev from "./assets/img/s-retail.png";
import Bub1 from "./assets/img/S-bubble1.png";
import Bub2 from "./assets/img/S-bubble2.png";
import Bub3 from "./assets/img/S-bubble3.png";
import Port_img from "./assets/img/port.png";
import Cl1 from "./assets/img/portfolio/Cl-1.png";
import Cl2 from "./assets/img/portfolio/Cl-2.png";
import Cl3 from "./assets/img/portfolio/Cl-3.png";
import Cl4 from "./assets/img/portfolio/Cl-4.png";
import Cl5 from "./assets/img/portfolio/Cl-5.png";
import Cl6 from "./assets/img/portfolio/Cl-6.png";
import Cl7 from "./assets/img/portfolio/Cl-7.png";
import Cl8 from "./assets/img/portfolio/Cl-8.png";
import Cl9 from "./assets/img/portfolio/Cl-9.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Tab from "./assets/img/tab2.png";
import Portimg_1 from "./assets/img/port_img1.png";
import Portimg_2 from "./assets/img/port_img2.png";
import Portimg_3 from "./assets/img/port_img3.png";
import Portimg_4 from "./assets/img/port_img4.png";
import Portimg_5 from "./assets/img/port_img5.png";
import Portimg_6 from "./assets/img/port_img6.png";
import Portimg_7 from "./assets/img/port_img7.png";
import Portimg_8 from "./assets/img/port_img8.png";
import Portimg_9 from "./assets/img/port_img9.png";
import Portimg_10 from "./assets/img/port_img10.png";
import Portimg_11 from "./assets/img/port_img11.png";
import Portimg_12 from "./assets/img/port_img12.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";

class hospitalmanagement extends React.Component
{
    render(){
       
        return(
            <div>
                <section  className="breadcrum-area breadcrumb-banner single-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left">
                                    <h1 className="title h2">Hospital Management System</h1>
                                    <p>A quick view of industry specific problems solved with design by the awesome team at Keystroke.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail">
                                    <img className="img-fluid" src={Hospital} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Bub1} alt="icon" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Bub2} alt="icon" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Bub3} alt="icon" />
                        </li>
                    </ul>  
                </section>
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-heading heading-left mb-0">
                                    <h3 className="title">Hospital Management System</h3>
                                </div>
                                <p>Hospital Management System is an integrated end-to-end web-based software application intended to make the administrative and management work at a hospital streamlined.</p>
                                <p>The software application covers every necessity of hospital administration including effective and efficient decision with regard to patient care, hospital administration and finances.</p>
                                <p>TAGCS aids the hospitals to coordinate their activities. It paves the way to prepare financial statements swiftly and efficiently. With the help of the Hospital Management System one can coordinate patient movement and can update patient related information consistently with reference to medicine administered and visits.</p>
                                <p>This application provides billing facility for to prepare bills online. It also enables us to coordinate employee duties, employee salary, and also collect, organize and retrieve patient information spontaneously.</p>
                                <p>Hospital Management System comforts the hospital administration to maintain the track records of regular or recurrent purchase and disposable of various consumable materials used in a hospital. Some of the examples are cotton, gauge, bottles, needles, syringes, sachets, plastic bags etc. The easy interoperability of the application allows the administrator to access the various modules from the main system of the hospital remotely from any location via a computer and an internet connection.</p>
                                <a className="axil-btn btn-fill-primary" href="https://tagit.store/hospital-pricing.html" target="blank">Get it Now</a>
                            </div>
                            {/* <div class="col-lg-6 offset-xl-1">
                                <div class="why-choose-us">
                                    <div class="section-heading heading-left">
                                    <table class="table striped">
                                                <thead className="thear_align">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Particulars</th>
                                                        <th>S</th>
                                                        <th>G</th>
                                                        <th>D</th>
                                                        <th>P</th>
                                                        <th>T</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Configuration</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Customer Registration</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Loan Enroll</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Loan Close</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Basic Report</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Extended Report</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Exist Loan Update</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Day Update</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>User Creations</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>User Rights</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>PA Account</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>Notice Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>13</td>
                                                        <td>Log</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>14</td>
                                                        <td>Web Cam For Customer Registration</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>15</td>
                                                        <td>Biometric Authentications</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>16</td>
                                                        <td>Barcode Process For Loan Receipt</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>17</td>
                                                        <td>Dashboard Alert For Over Due Loans</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>18</td>
                                                        <td>SMS Alerts To Customers</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>19</td>
                                                        <td>Group Loan Close & Renewal Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>20</td>
                                                        <td>Hypothecation Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>21</td>
                                                        <td>Online Core Integrations</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <Gototop/>
            </div>
        )
    }
}
export default hospitalmanagement