import React from "react";
import  { useState } from 'react';
import { Outlet, Link ,NavLink,} from "react-router-dom";
import {Offcanvas, Button} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import * as Bi from "react-icons/bi";
// import Menubtn from "./offcanvas";
import Logo from "./assets/img/logo.png";
import $ from "jquery";

$(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
        $("#header").addClass("header-scrolled");
    } else {
        $("#header").removeClass("header-scrolled");
    }
});

var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/6164103c86aee40a5735f5eb/1fhndurf5';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

class Header extends React.Component
{
    menuClick = ()=> {
        var element = document.getElementById("menu");
        element.classList.remove("show");
 




      }


      
     render(){
        return(
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg menu_bg p-1" id="header">
                        <div className="container p-0">
                            <Link to="/">
                                <a className="nav-brand">
                                    <img className="img-fluid logo_img" src={Logo}  alt="logo"/>
                                </a>
                            </Link>
                            <button id="hamburger-menu" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu">
                               <span className="navbar-toggler-icon " ><Bi.BiMenu className="menu_icon"/></span>
                            </button>
                            
                            <div className="collapse navbar-collapse" id="menu">
                                <ul className="navbar-nav ms-auto menu text-center" >
                                    <li className="nav-item " >
                                        <NavLink exact activeClassName="active" to="/" onClick={() => this.menuClick()}  className={`${this.props.className} nav_border`}>HOME</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        
                                        <NavLink activeClassName="active" to="/about" onClick={() => this.menuClick()}  className={`${this.props.className} nav_border`} >ABOUT</NavLink>
                                       
                                    </li>
                                    <li className="nav-item">
                                        
                                        <NavLink activeClassName="active" to="/services" onClick={() => this.menuClick()}  className={`${this.props.className} nav_border`}>SERVICES</NavLink>
                                        
                                    </li>
                                    <li className="nav-item">
                                        
                                        <NavLink activeClassName="active" to="/portfolio" onClick={() => this.menuClick()}  className={`${this.props.className} nav_border`}>PORTFOLIO</NavLink>
                                        
                                    </li>
                                    <li className="nav-item">
                                        
                                        <NavLink activeClassName="active" to="/products" onClick={() => this.menuClick()}  className={`${this.props.className} nav_border`}>PRODUCT</NavLink>
                                        
                                    </li>
                                    <li className="nav-item">
                                      
                                        <NavLink activeClassName="active" to="/careers" onClick={() => this.menuClick()}  className={`${this.props.className} nav_border`}>CAREERS</NavLink>
                                       
                                    </li>
                                    <li className="nav-item">
                                        <Link  to="/contact" onClick={() => this.menuClick()}  className={`${this.props.className} btn cont-btn nav_border`}> CONTACT US</Link>
                                     </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
                <Outlet />
            </div>


            
        )
    }

}
export default Header;