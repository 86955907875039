import React, { useState } from "react";
//import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import * as Bi from "react-icons/bs";
import { FloatingLabel, Form, Button, Alert } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Android from "./assets/img/career-1.png";
import IOS from "./assets/img/career-2.png";
import Dotnet from "./assets/img/career-3.png";
import PHP from "./assets/img/career-4.png";
import HalfCir from "./assets/img/half-circle.png";
import Line1 from "./assets/img/line-4.png";
import Career from "./assets/img/career.png";
import Php from "./assets/img/php.png";
import Donet from "./assets/img/dotnet.png";
import Ios from "./assets/img/ios.png";
import Adroid from "./assets/img/android.png";
import Gototop from "./gototop";
import axios from "axios";

const Careers = () => {

    const [username, setUsername] = useState('');
    const [usernameErr, setUsernameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [gender, setGender] = useState('');
    const [genderErr, setGenderErr] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [phoneNumberInput, setPhoneNumberInput] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [platform, setPlatform] = useState('');
    const [platformErr, setPlatformErr] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
  
    const handleUserName = (value) => {
        setUsername(value);
        if (value === '') {
          setUsernameErr('Please Enter the Username');
        } else {
          setUsernameErr('');
        }
      };
      
      const handleEmail = (value) => {
        setEmail(value);
        const emailRegex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;
        if (value === '') {
          setEmailErr('Please enter your email.');
        } else if (!emailRegex.test(value)) {
          setEmailErr('Please enter a valid email.');
        } else {
          setEmailErr('');
        }
      };
      
      const handleGender = (value) => {
        setGender(value);
      
        if (value === '' || value === 'Select') {
          setGenderErr('Please select the Gender');
        } else {
          setGenderErr('');
        }
      };
      
      const handlePhoneNumberChange = (value) => {
        setPhoneNumberValue(value);
        const phNoRegex = /^\d*\.?\d*$/;
        if (value === '') {
          setPhoneNumberError('Please enter your Phone Number.');
        } else if (!phNoRegex.test(value)) {
          setPhoneNumberError('Please enter Valid Mobile Number.');
        } else {
          setPhoneNumberError('');
        }
      };
      
      const handlePlatform = (value) => {
        setPlatform(value);
      
        if (value === '' || value === 'Select') {
          setPlatformErr('Please select the Platform');
        } else {
          setPlatformErr('');
        }
      };
      
      const handleFileChange = (e) => {
        const file = e.target.files[0];
      
        // Check if a file is selected or not
        if (!file) {
          setFileError('Please select a file.');
        } else {
          setSelectedFile(file);
          setFileError('');
        }
      };
      
      const handleSumbit = async (e) => {
        e.preventDefault();
      
        handleUserName(username);
        handleEmail(email);
        handleGender(gender);
        handlePhoneNumberChange(phoneNumberValue);
        handlePlatform(platform);
      
        // Check if any validation errors exist
        // if (usernameErr || emailErr || genderErr || phoneNumberError || platformErr) {
        //   // Do not proceed with form submission if there are errors
        //   console.error('Please fill in all the required fields correctly.');
        //   return;
        // }
      
        // // Check if a file is selected
        // if (!selectedFile) {
        //   setFileError('Please select a file.');
        //   return;
        // }
      
        // If no validation errors, proceed with sending the form data to the server
        if (username || email || gender || phoneNumberValue || platform) {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('gender', gender);
        formData.append('phoneNumber', phoneNumberValue);
        formData.append('platform', platform);
        formData.append('file', selectedFile);
      
        // Send the form data to the server
        try {
          const response = await axios.post('https://tagcs.net:3002/careers', formData);
          // Handle the server response if needed
          console.log('Form data sent successfully!', response.data);
          setAlertMessage('Thank you for reaching out to us. We will get back to you soon.');
          setShowAlert(true);
        } catch (error) {
          // Handle any error that occurs during form submission
          console.error('Error submitting form:', error);
        }
      }
      setUsername("");
      setEmail("");
      setGender("");
      setPhoneNumberValue("");
      setPlatform("");
      setFileError("");
      };
      

        return(
            <div>
                <section  className="bg-img">
                    <Rb.Container className="about_banner">
                            <Rb.Row className="banner_height_set align-items-center ">
                                <Rb.Col sm={12} md={6} lg={6} className="col_align" >
                                    <div className="banner_para_align">
                                        <p className="about_head_para">CAREERS</p>
                                        <h2 className="about_h2">Current Opportunities</h2>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={6} lg={6}>
                                    <Rb.Image src={Career} alt="services_img"  className="about_img_align"/>
                                </Rb.Col>
                            </Rb.Row>
                        </Rb.Container>
                </section>

                <section className="section section-padding-equal bg-color-light">
                    <div className="container">
                        <div className="product">
                            {/* <div class="section-heading text-start mb-3">
                                <p class="subtitle">Our Products</p>
                                <h2 class="title">We understand your requirement and provide quality works.</h2>
                            </div> */}
                            <div className="row pages">
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 mx-sm-auto">
                                    <div className="product-grid page career-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={PHP} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">UI/UX&nbsp;DESIGN</h5>
                                            <div className="floatLeft">
                                            <p className="">Mandatory Experience with HTML5, CSS3, Bootstrap, Java Script, jQuery, Vue/React/Angular CLI, API Integration, Adobe Photoshop, Adobe illustrator etc...</p>
                                            <p className="">Must have knowledge in Responsive Design</p>
                                            <p className="">Experience with Web UI Design, App UI Design, Logo Design, Develop UI Mock-ups and Prototypes.</p>
                                            <p className="">Exp: 1+ Years</p>
                                            <a className="more-btn" href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#myModal">Apply Now<Bi.BsArrowRightShort className="more-btn read_icon"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 mx-sm-auto">
                                    <div className="product-grid page career-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Dotnet} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">PHP&nbsp;LARAVEL</h5>
                                            <div className="floatLeft">
                                            <p className="">Good understanding with hands on experience on PHP-Laravel framework. Writing web services on PHP.</p>
                                            <p className="">Thorough understanding of OOP Concepts. Must have good communication skills.</p>
                                            <p className="">Working experience on HTML5/CSS3/jQuery. Knowledge on REST Service integration (JSON).</p>
                                            <p className="">Exp: 3+ Years</p>
                                            <a className="more-btn" href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#myModal">Apply Now<Bi.BsArrowRightShort className="more-btn read_icon"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 mx-sm-auto">
                                    <div className="product-grid page career-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={IOS} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">PHP&nbsp;CODEIGNITER</h5>
                                            <div className="floatLeft">
                                            <p className="">Good understanding with hands on experience on PHP-Codeigniter framework. Writing web services on PHP.</p>
                                            <p className="">Thorough understanding of OOP Concepts. Must have good communication skills.</p>
                                            <p className="">Working experience on HTML5/CSS3/jQuery. Knowledge on REST Service integration (JSON).</p>
                                            <p className="">Exp: 3+ Years</p>
                                            <a className="more-btn" href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#myModal">Apply Now<Bi.BsArrowRightShort className="more-btn read_icon"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 mx-sm-auto">
                                    <div className="product-grid page career-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Android} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">MOBILE&nbsp;APP</h5>
                                            <div className="floatLeft">
                                            <p className="">Experience with React Native/Flutter, Working experience on Android & IOS.</p>
                                            <p className="">Experience with Android SDK. Experience working with remote data via REST and JSON. Experience with third-party libraries and APIs.</p>
                                            <p className="">Working knowledge of the general mobile landscape, architectures, trends, and emerging technologies. Solid understanding of the full mobile development life cycle.</p>
                                            <p className="">Exp: 3+ Years</p>
                                            <a className="more-btn" href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#myModal">Apply Now<Bi.BsArrowRightShort className="more-btn read_icon"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-6">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={HalfCir} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Line1} alt="Circle" />
                        </li>
                    </ul>
                </section>
                <Gototop/>
                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h4 className="modal-title">Job Application Form</h4>
                        <button type="button"  className="close popup-close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div>
                                    {showAlert && (
                                      <Alert variant="success" onClose={() => setShowAlert(false)} dismissible delay={3000} className="mt-2">
                                      {alertMessage}
                                      </Alert>
                                    )}
                                    <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="" value={username} onChange={(e)=>setUsername(e.target.value)} onBlur={()=>handleUserName(username)}/>
                                        <span className="text-danger">{usernameErr}</span><br></br>
                                        <Form.Label className="mt-3">Email</Form.Label>
                                        <Form.Control type="email" placeholder="" value={email} onChange={(e)=>setEmail(e.target.value)} onBlur={()=>handleEmail(email)}/>
                                        <span className="text-danger">{emailErr}</span><br></br>
                                        <p className="mt-3 form-label">Gender</p>
                                        <select className='form-select' 
                                        value={gender} 
                                        onChange={(e) => setGender(e.target.value)}
                                        onBlur={() => handleGender(gender)}>
                                            <option value="">Select</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        <span className="text-danger">{genderErr}</span><br></br>
                                        <Form.Label className="mt-3">Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            maxLength={10}
                                            value={phoneNumberInput ? phoneNumberValue : ''}
                                            onChange={(e) => {
                                            const input = e.target.value;
                                            const manipulatedValue = input
                                                .replace(/[^\d]/g, '') // Remove non-numeric characters
                                                .replace(/^0[^.]/, '0'); // Remove leading non-numeric characters except '0'
                                            setPhoneNumberValue(manipulatedValue);
                                            setPhoneNumberInput(input);
                                            }}
                                            onBlur={() => handlePhoneNumberChange(phoneNumberValue)}
                                            onFocus={() => setPhoneNumberError('')} // Reset to original input on focus
                                        />
                                        <span className="text-danger">{phoneNumberError}</span><br></br>
                                        <p className="mt-3 form-label">Platform</p>
                                    <select className='form-select' 
                                        value={platform} 
                                        onChange={(e) => setPlatform(e.target.value)}
                                        onBlur={() => handlePlatform(platform)}>
                                        <option value="Select">--select--</option>
                                        <option value="Php Developer">Php Developer</option>
                                        <option value="Dotnet Developer">Dotnet Developer</option>
                                        <option value="IOS Developer">IOS Developer</option>
                                        <option value="Android Developer">Android Developer</option>
                                    </select>
                                    <span className="text-danger">{platformErr}</span><br></br>
                                    </Form.Group>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Resume</Form.Label>
                                        <Form.Control type="file" onChange={handleFileChange}/>
                                        {fileError && <span className="text-danger">{fileError}</span>}
                                    </Form.Group>
                                    <button type="submit" class="axil-btn btn-fill-primary btn-fluid btn-primary"  name="submit-btn" onClick={handleSumbit}>Submit</button>
                                </Form>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    </div>
                </div>
            </div>
        )
}
export default Careers;