import React from "react";
import {Link} from "react-router-dom";
import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Bi from "react-icons/bs";
import "./home.css";
import TCir1 from "./assets/img/top-cir1.png";
import Food from "./assets/img/s-food.png";
import Pawn from "./assets/img/s-pawn.png";
import Support from "./assets/img/s-support.png";
import Hospital from "./assets/img/s-medical.png";
import Hr from "./assets/img/s-hr.png";
import CusDev from "./assets/img/s-retail.png";
import Bub1 from "./assets/img/S-bubble1.png";
import Bub2 from "./assets/img/S-bubble2.png";
import Bub3 from "./assets/img/S-bubble3.png";
import Port_img from "./assets/img/port.png";
import Cl1 from "./assets/img/portfolio/Cl-1.png";
import Cl2 from "./assets/img/portfolio/Cl-2.png";
import Cl3 from "./assets/img/portfolio/Cl-3.png";
import Cl4 from "./assets/img/portfolio/Cl-4.png";
import Cl5 from "./assets/img/portfolio/Cl-5.png";
import Cl6 from "./assets/img/portfolio/Cl-6.png";
import Cl7 from "./assets/img/portfolio/Cl-7.png";
import Cl8 from "./assets/img/portfolio/Cl-8.png";
import Cl9 from "./assets/img/portfolio/Cl-9.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Tab from "./assets/img/tab2.png";
import Portimg_1 from "./assets/img/port_img1.png";
import Portimg_2 from "./assets/img/port_img2.png";
import Portimg_3 from "./assets/img/port_img3.png";
import Portimg_4 from "./assets/img/port_img4.png";
import Portimg_5 from "./assets/img/port_img5.png";
import Portimg_6 from "./assets/img/port_img6.png";
import Portimg_7 from "./assets/img/port_img7.png";
import Portimg_8 from "./assets/img/port_img8.png";
import Portimg_9 from "./assets/img/port_img9.png";
import Portimg_10 from "./assets/img/port_img10.png";
import Portimg_11 from "./assets/img/port_img11.png";
import Portimg_12 from "./assets/img/port_img12.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";

class supportticket extends React.Component
{
    render(){
       
        return(
            <div>
                <section  className="breadcrum-area breadcrumb-banner single-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left">
                                    <h1 className="title h2">Support Ticketing Tools</h1>
                                    <p>A quick view of industry specific problems solved with design by the awesome team at Keystroke.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail">
                                    <img className="img-fluid" src={Support} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Bub1} alt="icon" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Bub2} alt="icon" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Bub3} alt="icon" />
                        </li>
                    </ul>  
                </section>
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-heading heading-left mb-0">
                                    <h3 className="title">Support Ticketing Tools</h3>
                                </div>
                                <p>Ticketing Management aids automate help desk tasks and saves ample of time for IT technicians and support Employees. Now it is easy to manage all end-user trouble tickets and track service request lifecycle from ticket creation to resolution from a single, centralized help desk management web interface.</p>
                                <p>Our Ticket Management tool aids you to issue tracking and service request fulfilment. This tool has automatic email to ticket conversion including attachments.</p>
                                <p>Some of the features of our Ticketing Management Tool are Load balancing of ticket assignment, Quick ticket creation templates, Bulk Ticket Updates, Easy configuring of multiple inbound email addresses, unlimited user-defined fields for dynamic ticket forms. Based on the technician's skill set, availability, location, department and work load you can allot automated ticket routing. It simplifies assignment schedule and saves a lot of time.</p>
                                <p>This tool aids intuitive service request and self-service portal for end-users. For import and export reports the file extensions used are:</p>
                                <ul>
                                    <li>Import Tickets - CSV or TSV, Export Tickets - TSV or PDF, built-in PDF generator.</li>
                                    <li>Creation of FAQ articles can be done via Ticketing Management Tool.</li>
                                </ul>
                                <a className="axil-btn btn-fill-primary" href="https://tagit.store/ticketing-pricing.html" target="blank">Get it Now</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Gototop/>
            </div>
        )
    }
}
export default supportticket