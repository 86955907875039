import React from "react";
import {Link} from "react-router-dom";
import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Bi from "react-icons/bs";
import "./home.css";
import TCir1 from "./assets/img/top-cir1.png";
import Food from "./assets/img/s-food.png";
import Pawn from "./assets/img/s-pawn.png";
import Support from "./assets/img/s-support.png";
import Hospital from "./assets/img/s-medical.png";
import Hr from "./assets/img/s-hr.png";
import CusDev from "./assets/img/s-retail.png";
import Bub1 from "./assets/img/S-bubble1.png";
import Bub2 from "./assets/img/S-bubble2.png";
import Bub3 from "./assets/img/S-bubble3.png";
import Port_img from "./assets/img/port.png";
import Cl1 from "./assets/img/portfolio/Cl-1.png";
import Cl2 from "./assets/img/portfolio/Cl-2.png";
import Cl3 from "./assets/img/portfolio/Cl-3.png";
import Cl4 from "./assets/img/portfolio/Cl-4.png";
import Cl5 from "./assets/img/portfolio/Cl-5.png";
import Cl6 from "./assets/img/portfolio/Cl-6.png";
import Cl7 from "./assets/img/portfolio/Cl-7.png";
import Cl8 from "./assets/img/portfolio/Cl-8.png";
import Cl9 from "./assets/img/portfolio/Cl-9.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Tab from "./assets/img/tab2.png";
import Portimg_1 from "./assets/img/port_img1.png";
import Portimg_2 from "./assets/img/port_img2.png";
import Portimg_3 from "./assets/img/port_img3.png";
import Portimg_4 from "./assets/img/port_img4.png";
import Portimg_5 from "./assets/img/port_img5.png";
import Portimg_6 from "./assets/img/port_img6.png";
import Portimg_7 from "./assets/img/port_img7.png";
import Portimg_8 from "./assets/img/port_img8.png";
import Portimg_9 from "./assets/img/port_img9.png";
import Portimg_10 from "./assets/img/port_img10.png";
import Portimg_11 from "./assets/img/port_img11.png";
import Portimg_12 from "./assets/img/port_img12.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";

class retailapplication extends React.Component
{
    render(){
       
        return(
            <div>
                <section  className="breadcrum-area breadcrumb-banner single-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left">
                                    <h1 className="title h2">Retail Application</h1>
                                    <p>A quick view of industry specific problems solved with design by the awesome team at Keystroke.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail">
                                    <img className="img-fluid" src={CusDev} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Bub1} alt="icon" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Bub2} alt="icon" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Bub3} alt="icon" />
                        </li>
                    </ul>  
                </section>
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-heading heading-left mb-0">
                                    <h3 className="title">Retail Application</h3>
                                </div>
                                <p>To conquest the digital combat, retailers must essentially rethink how applications should be built and deployed. Retailers can no longer be idlers in adopting new technologies. Now the necessity of knowing new technologies and how software applications are implemented in the business field has become a vital task.</p>
                                <p>The advancement of several mobile applications has changed the behaviour of consumers and their expectation. To find an expedient solution for business challenges and to revolutionize business processes every business concern requires the power of mobile applications.</p>
                                <p>Adopting a software strategy can spur growth of a business. These new technologies are capable in shaping new markets and in grasping more consumers and also helps in creating new services and best offerings.</p>
                                <p>Pioneer from both IT and the business must toil together closely to organize new business solutions to help conquest the digital combat and drive competitive advantage.</p>
                                <a className="axil-btn btn-fill-primary" href="https://tagit.store/billing_pricing.html" target="blank">Get it Now</a>
                            </div>
                            <div className="col-lg-6 offset-xl-1">
                                <div className="why-choose-us">
                                    <div className="section-heading heading-left">
                                    <table className="table striped">
                                                <thead classNameName="thear_align">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Particulars</th>
                                                        <th>S</th>
                                                        <th>G</th>
                                                        <th>D</th>
                                                        <th>P</th>
                                                        <th>T</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Product Configuration</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Billing</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Sales Report</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Customer Registration</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Credit Bill</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Debit Note</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Credit Payment Management</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Consolidate Report for Sales</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>Sales Return & Cancel</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>Purchase</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>Supplier Registration</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>Purchase Return</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>13</td>
                                                        <td>Purchase Cancel</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>14</td>
                                                        <td>Purchase Payment</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>15</td>
                                                        <td>Credit Note</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>16</td>
                                                        <td>Consolidate Report for Purchase</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>17</td>
                                                        <td>Stock report</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>18</td>
                                                        <td>Bar code Integration</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>19</td>
                                                        <td>Stock conversion</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>20</td>
                                                        <td>Daily Stock Report</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>21</td>
                                                        <td>Damage Stock Process</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>22</td>
                                                        <td>Quotation</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>23</td>
                                                        <td>Day book, Bank & Cheque</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>24</td>
                                                        <td>User creation & rights</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>25</td>
                                                        <td>Log</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>26</td>
                                                        <td>Consolidate and Tax Report</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>27</td>
                                                        <td>Due Report & Short Report</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>28</td>
                                                        <td>Online Integration</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td>29</td>
                                                        <td>Whole sale Billing</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Gototop/>
            </div>
        )
    }
}
export default retailapplication